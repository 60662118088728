import { Empty, Skeleton, Button, Switch, message, Popconfirm } from "antd";
// import { DateTime } from "luxon";
import { findIndex } from "lodash";
import { Link } from "react-router-dom";
import { useFetch } from "../../../hooks";
import Table from "../../../components/Table";
import defaults from "../../../constants";
import Layout from "../../../components/Layout";
import { areasService } from "../../../services/sectionsAreas";

const Areas = () => {
  const { datos, loading, error, setData } = useFetch(areasService.getAll);
  // just because the data has nested data
  let { data } = datos || {};
  console.log("data", datos);
  const columns = [
    {
      title: "Secciones",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      //   responsive: ["md"],
    },
    {
      title: "Areas",
      dataIndex: "count_areas",
      key: "count_areas",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: () => "Act/Dsc",
      key: "id",
      width: 80,
      align: "center",
      render: (data, record, index) => (
        <Switch
          checked={data.is_active}
          onChange={() => {
            handleStatus(data.id, data.is_active);
          }}
        />
      ),
    },
    {
      title: () => "Editar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => (
        <Button type='link' href='#' className='no-padding'>
          <Link to={"/admin/areas/" + record.id + "/edit"}>
            <div style={{ height: "30px", width: "30px", borderRadius: 30, backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)` }}>
              <span className='icon icon-pencil'></span>
            </div>
          </Link>
        </Button>
      ),
    },
    {
      title: () => "Eliminar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => (
        <Popconfirm
          title={`Are you sure to delete this Section: ${record.name}`}
          onConfirm={() => {
            handleDelete(record.id);
          }}
          okText='Yes'
          cancelText='No'
        >
          <Button type='link' href='#' className='no-padding'>
            <div style={{ height: "30px", width: "30px", borderRadius: 30, backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)` }}>
              <span className='icon icon-trash'></span>
            </div>
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const handleStatus = (id, value) => {
    // Esto se uso ya que la tabla de ANtd trae el inex actual de la pagina actual
    const index = findIndex(data, function (item) {
      return item.id == id;
    });
    areasService.disable(id).then((res) => {
      if (res.status === 200) {
        let newData = [...data];
        newData[index]["is_active"] = !value;
        setData((prevState) => {
          console.log("prevState", prevState);
          console.log("prevState", newData);
          return {
            ...prevState,
            data: { data: newData },
          };
        });
      }
    });
  };
  const handleDelete = (id) => {
    areasService._delete(id).then(async (res) => {
      if (res.status === 200) {
        const newData = await areasService.getAll();
        setData((prevState) => {
          return {
            ...prevState,
            data: newData.data,
            loading: false,
          };
        });
        message.success("Section deleted successfully");
      }
    });
  };
  return (
    <Layout
      title='Secciones'
      action={
        <Button type='primary'>
          <Link to='/admin/areas/add'>Agregar Sección</Link>
        </Button>
      }
    >
      {error ? (
        <Empty className='mt-30 mt-80-md' description='Lo sentimos no hay datos disponibles' />
      ) : (
        <Skeleton loading={loading}>
          <Table
            // pagination={{ current: meta ? meta.current_page : null, pageSize: meta ? meta.per_page : null, total: meta ? meta.total : null }}
            loading={false}
            columns={columns}
            data={data}
          />
        </Skeleton>
      )}
    </Layout>
  );
};

export default Areas;
