import React, { useState, useEffect, useContext } from "react";
import { Empty, Skeleton, Button, Tag } from "antd";
// import { useFetch } from "../../hooks";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import Table from "../../components/Table";
import DB from "../../store";
// import defaults from "../../constants";
import Layout from "../../components/Layout";
import { auditService } from "../../services/audits";
import { AbilityContext } from "../../components/Can";

const AuditsEdit = () => {
  const [data, setData] = useState({
    loading: true,
    data: [],
  });
  const ability = useContext(AbilityContext);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await auditService.getAll();
        DB.setItem("audits", res.data)
          .then(function () {
            return DB.getItem("audits");
          })
          .then(function (value) {
            setData({
              loading: false,
              data: value,
            });
          })
          .catch(function (err) {
            alert(err);
          });
      } catch (error) {
        DB.getItem("audits")
          .then(function (value) {
            setData({
              loading: false,
              data: value,
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    };
    getData();
  }, []);

  // useEffect(() => {
  //   console.log(process.env.REACT_APP_IMGUR_CLIENT_ID);
  //   const url = "https://5cec507b77d47900143b930b.mockapi.io/vcm/";
  //   axios
  //     .get(url + "tours")
  //     .then((res) => {
  //       DB.setItem("tours", res.data)
  //         .then(function () {
  //           return DB.getItem("tours");
  //         })
  //         .then(function (value) {
  //           setData(value);
  //         })
  //         .catch(function (err) {
  //           // we got an error
  //         });
  //     })
  //     .catch(function (err) {
  //       DB.getItem("tours")
  //         .then(function (value) {
  //           console.log(value);
  //           setData(value);
  //         })
  //         .catch(function (err) {
  //           console.log(err);
  //         });
  //     });
  // }, []);
  const columns = [
    {
      title: "Codigo",
      dataIndex: "id",
      key: "id",
      render: (text) => <a>{text}</a>,
      responsive: ["md"],
    },
    {
      title: "Hotel",
      dataIndex: "property",
      key: "property",
      render: (text) => <strong>{text.name}</strong>,
      responsive: ["md"],
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>
          {DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      ),
    },
    {
      title: "Código Hotel",
      dataIndex: "property",
      key: "property",
      render: (text) => <span>{text.code}</span>,
    },
    {
      title: "Auditor",
      dataIndex: "user",
      key: "user",
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: "Estado",
      key: "finished_at",
      dataIndex: "finished_at",
      render: (tag) => {
        let color = !tag ? "geekblue" : "green";
        const text = tag ? "Finished" : "In Progress";
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "",
      key: "id",
      width: 180,
      fixed: "right",
      render: (text, record) => (
        <Link to={`/audits/${record.id}`}>
          <Button type="primary" block ghost>
            Show Details
          </Button>
        </Link>
      ),
    },
  ];
  //  TODO: change to BASE URL on .env
  return (
    <Layout
      title="Auditorias Programadas"
      action={
        ability.can("create", "audit") ? (
          <Link to={"/audits/create"}>
            <Button type="primary" className="">
              Nueva auditoria
            </Button>
          </Link>
        ) : null
      }
    >
      {data.data && data.data.length == 0 ? (
        <Empty
          className="mt-30 mt-80-md"
          description="Lo sentimos no hay datos disponibles"
        />
      ) : (
        <Table loading={data.loading} columns={columns} data={data.data} />
      )}
    </Layout>
  );
};

export default AuditsEdit;
