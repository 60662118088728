import { authHeader } from "./auth";
import apiAxios from "./apiAxios";
import { handleResponse } from "./utils";
import { filter } from "lodash";

function getAll(page = 1) {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/audits?page=${page}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getAllRaw() {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/properties`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getAllFinished() {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/audits/finished`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function show(id) {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/audits/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function showAuditArea(id) {
  return apiAxios
    .get(`/api/audits/area/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function create(data) {
  return apiAxios
    .post("/api/audits", data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function update(id, data) {
  return apiAxios
    .put(`/api/properties/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function disable(id) {
  return apiAxios
    .get(`/api/properties/${id}/active`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function _delete(id) {
  return apiAxios
    .delete(`/api/properties/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function deleteImage(id, data) {
  return apiAxios
    .post(`/api/audits/area/${id}/gallery/delete`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function evaluate(id, data) {
  return apiAxios
    .post(`/api/audits/area/${id}/check`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getScore(values) {
  const YES = filter(values.answers, (x) => x.value == 1) || [];
  const NO = filter(values.answers, (x) => x.value == 0) || [];
  const NA = filter(values.answers, (x) => x.value == 2) || [];
  const currentActive = YES.length;
  // const currentActive = YES.length + NO.length + NA.length;
  const getPercentage = (partialValue) => {
    let total = YES.length + NO.length;
    if (!total) total = values.answers.length;
    return ((partialValue * 100) / total).toFixed(2);
  };
  const AE = getPercentage(currentActive);
  return AE;
}

export const auditService = {
  getAll,
  getAllFinished,
  getAllRaw,
  show,
  showAuditArea,
  disable,
  _delete,
  create,
  update,
  evaluate,
  getScore,
  deleteImage,
};
