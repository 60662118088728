import React, { useState } from "react";
import { authHeader } from "../../../services/auth";
import { Upload, Modal, message } from "antd";
import { auditService } from "../../../services/audits";
const UploadImages = ({ id, images, disabled }) => {
  // console.log("images", images);
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const [estado, setEstado] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: images,
  });
  const { previewVisible, previewImage, fileList, previewTitle } = estado;
  const uploadButton = (
    <div>
      <span className="icon icon-cloud-upload icon--md"></span>
      <div style={{ marginTop: 8 }}>
        Upload <br /> a photo
      </div>
    </div>
  );
  const handleCancel = () => setEstado({ ...estado, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setEstado({
      ...estado,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  const handleDelete = async (file) => {
    // A callback function, will be executed when removing file button is clicked, remove event will be prevented when return value is false or a Promise which resolve(false) or reject
    const imagePath = file.response || file.url;
    const req = await auditService.deleteImage(id, { image_url: imagePath });
    if (!req.data.message) {
      return false;
    }
    message.success(`${file.name} se eliminó correctamente`);
  };

  const handleChange = ({ fileList, file }) => {
    if (file.status === "done") {
      message.success(`${file.name} se subió correctamente`);
    }
    setEstado({ ...estado, fileList });
  };
  // console.log(process.env.REACT_APP_CDN_BASE_URL);
  return (
    <>
      <Upload
        disabled={disabled}
        accept=".jpg"
        className="mt-10"
        name="brand_img"
        action={`${process.env.REACT_APP_BASE_URL_API}/api/audits/area/${id}/upload`}
        listType="picture-card"
        headers={authHeader(true)}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleDelete}
      >
        {fileList.length >= 8 ? null : disabled ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadImages;
