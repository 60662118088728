import React, { useState, useEffect } from "react";
import { Skeleton, Row, Col, Button, Divider, Space, Empty } from "antd";
import { Formik, useFormikContext } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import * as Yup from "yup";
// import { DateTime } from "luxon";
import Layout from "../../../components/Layout";
import StripedListAntd from "../../../components/StripedList";
import { TitleBg } from "../../../components/Titles";
import DB from "../../../store";
import InfoLabel from "./InfoLabel";
import UploadImages from "./UploadImages";
import { auditService } from "../../../services/audits";

const auditSchema = Yup.object().shape({
  answers: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("Requerido"),
      value: Yup.string().required("Requerido").nullable(),
    })
  ),
});

const AuditCheck = () => {
  const [data, setData] = useState({
    loading: true,
    info: {},
  });
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log(navigate)
  useEffect(() => {
    console.log(id);
    const getData = async () => {
      try {
        const res = await auditService.showAuditArea(id);
        DB.setItem("area-audit_" + id, res.data)
          .then(function () {
            return DB.getItem("area-audit_" + id);
          })
          .then(function (values) {
            // console.log()
            const initialValuesQuestions = values.questions.map((ele) => {
              const value = ele.not_apply === 0 ? ele.check : 2;
              if (!values.audited_at) {
                return {
                  id: ele.id,
                  value: undefined,
                };
              }
              return {
                id: ele.id,
                value,
              };
            });
            setData({
              loading: false,
              info: values,
              initialValues: {
                answers: initialValuesQuestions,
                comments: res.data.observations,
              },
            });
          })
          .catch(function (err) {
            alert(err);
          });
      } catch (error) {
        DB.getItem("area-audit_" + id)
          .then(function (values) {
            const initialValuesQuestions = values.questions.map((ele) => {
              const value = ele.not_apply === 0 ? ele.check : 2;
              if (!values.audited_at) {
                return {
                  id: ele.id,
                  value: null,
                };
              }
              return {
                id: ele.id,
                value,
              };
            });
            setData({
              loading: false,
              info: values,
              initialValues: {
                answers: initialValuesQuestions,
                comments: values.observations,
              },
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    };
    getData();
  }, []);

  const handleSubmit = async (formProps, actions) => {
    try {
      const req = await auditService.evaluate(id, formProps);
      actions.setSubmitting(false);
      console.log(req);
      // if (req.data && req.data.message) {
      //   navigate("/audits/scheduled");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const GetScore = ({ score }) => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.answers && values.answers.length > 0) {
        const score = auditService.getScore(values);
        values.score = score;
      }
    }, [values.answers]);
    return null;
  };
  console.log(data.initialValues);
  return (
    <Layout title={"Auditorias"}>
      <Skeleton loading={data.loading} paragraph={{ rows: 20 }}>
        <Formik
          enableReinitialize={true}
          initialValues={data.initialValues}
          onSubmit={handleSubmit}
          validationSchema={auditSchema}
          render={(props) => {
            // console.log(props);
            return (
              <Form>
                <Row
                  type="flex"
                  justify="space-between"
                  className="primary-color mb-20 mt-15"
                >
                  <Col xs={12} lg={8}>
                    <p>
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <span className="icon icon-chevron-left"></span>
                      </Button>
                    </p>
                    <p>
                      <Space size="large" wrap={true}>
                        <span>
                          <strong>Hotel:</strong>{" "}
                          {data.info.audit.property.name}
                        </span>
                        <span>
                          <strong>Sección:</strong>{" "}
                          {data.info.questions[0].section}
                        </span>
                        <span>
                          <strong>Area:</strong> {data.info.questions[0].area}
                        </span>
                      </Space>
                    </p>
                  </Col>
                  <Col xs={12} lg={4} align="end">
                    <p className="primary-color">
                      <span>
                        <strong>Date & Time</strong>{" "}
                        {DateTime.fromISO(data.info.created_at).toLocaleString(
                          DateTime.DATETIME_SHORT
                        )}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row type="flex" gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
                  <Col xs={{ span: 12, order: 1 }} lg={{ span: 8, order: 1 }}>
                    <StripedListAntd
                      data={data.info.questions}
                      disabled={data.info.audited_at}
                    />
                  </Col>
                  <Col xs={{ span: 12, order: 3 }} lg={{ span: 4, order: 2 }}>
                    <TitleBg>
                      <strong>General Observation:</strong>
                    </TitleBg>
                    <Input.TextArea
                      disabled={data.info.audited_at}
                      placeholder="write an observation"
                      style={{
                        height: "calc(100% - 30px)",
                        backgroundColor: "rgba(171,171,171,0.05)",
                        borderRadius: 0,
                        border: "none",
                      }}
                      type="text"
                      name="comments"
                      autoSize={false}
                    />
                  </Col>
                  <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 3 }}>
                    <InfoLabel
                      values={props.values}
                      // total={data ? data.length : 0}
                    />
                  </Col>
                  <Col xs={{ span: 12, order: 4 }} className="mt-10">
                    <TitleBg>
                      <strong>PHOTOGRAPHIC SUPPORT:</strong>
                    </TitleBg>
                    {/* This feature is Asynchronus, only works if Audit exists :) */}
                    {(data.info.images && data.info.images.length > 0) ||
                    data.info.audited_at == null ? (
                      <UploadImages
                        id={id}
                        images={data.info.images}
                        disabled={data.info.audited_at}
                      />
                    ) : (
                      <Empty
                        className="mt-20 mt-25-md"
                        description="No se cargaron imágenes"
                      />
                    )}
                    <Divider />
                  </Col>
                  <Col
                    xs={{ span: 12, order: 5 }}
                    className="mt-10"
                    align="center"
                  >
                    <SubmitButton
                      disabled={data.info.audited_at}
                      size="large"
                      style={{ width: "180px" }}
                    >
                      Enviar
                    </SubmitButton>
                  </Col>
                </Row>
                <GetScore />
              </Form>
            );
          }}
        />
      </Skeleton>
    </Layout>
  );
};

export default AuditCheck;
