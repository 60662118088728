import { Empty, Skeleton, Button, Switch, message, Popconfirm } from "antd";
// import { DateTime } from "luxon";
import { findIndex } from "lodash";
import { Link } from "react-router-dom";
import { useFetch } from "../../../hooks";
import Table from "../../../components/Table";
import defaults from "../../../constants";
import Layout from "../../../components/Layout";
import Modal from "../../../components/Modal";
import NewPropertyForm from "./createForm/index.js";
import { propertyService } from "../../../services/property";
const Properties = () => {
  const {
    datos = {},
    loading,
    error,
    setData,
  } = useFetch(propertyService.getAll);
  // let { data } = datos || {};
  let {  data, meta = {} } = datos || {};
  console.log(datos)
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      //   responsive: ["md"],
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      render: (text) => <strong>{text}</strong>,
      //   responsive: ["md"],
    },
    {
      title: "General Manager",
      dataIndex: "manager",
      key: "manager",
      render: (text) => <span>{text}</span>,
      minWidth: 200,
      //   responsive: ["md"],
    },
    {
      title: "Habitaciones",
      dataIndex: "rooms",
      key: "rooms",
      render: (text) => <span>{text + " Habs."}</span>,
      minWidth: 200,
      //   responsive: ["md"],
    },
    {
      title: () => "Duplicar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => (
        <Modal
          type="link"
          buttonProps={{ className: "no-padding", href: "#" }}
          button={
            <div
              style={{
                height: "30px",
                width: "30px",
                borderRadius: 30,
                backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)`,
              }}
            >
              <span className="icon icon-file-add"></span>
            </div>
          }
          title="Duplicar propiedad"
        >
          <NewPropertyForm duplicate={true} id={record.id} />
        </Modal>
      ),
    },
    {
      title: () => "Act/Dsc",
      key: "id",
      width: 80,
      align: "center",
      render: (data, record, index) => (
        <Switch
          checked={data.is_active}
          onChange={() => {
            handleStatus(data.id, data.is_active);
          }}
        />
      ),
    },
    {
      title: () => "Editar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => (
        <Button type="link" href="#" className="no-padding">
          <Link to={"/admin/properties/" + record.id}>
            <div
              style={{
                height: "30px",
                width: "30px",
                borderRadius: 30,
                backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)`,
              }}
            >
              <span className="icon icon-pencil"></span>
            </div>
          </Link>
        </Button>
      ),
    },
    {
      title: () => "Eliminar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => (
        <Popconfirm
          title={`Are you sure to delete this Property: ${record.name}`}
          onConfirm={() => {
            handleDelete(record.id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" href="#" className="no-padding">
            <div
              style={{
                height: "30px",
                width: "30px",
                borderRadius: 30,
                backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)`,
              }}
            >
              <span className="icon icon-trash"></span>
            </div>
          </Button>
        </Popconfirm>
      ),
      // render: (text, record) => (
      //   <Button type='link' href='#' className='no-padding'>
      //     <div style={{ height: "30px", width: "30px", borderRadius: 30, backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)` }}>
      //       <span className='icon icon-trash'></span>
      //     </div>
      //   </Button>
      // ),
    },
  ];
  const handleUpdateData = (newData) => {
    setData((prevState) => {
      return { ...prevState, data: [...prevState.data, newData] };
    });
  };
  const handleStatus = (id, value) => {
    const index = findIndex(data, function (item) {
      return item.id == id;
    });
    propertyService.disable(id).then((res) => {
      if (res.status === 200) {
        let newData = [...data];
        newData[index]["is_active"] = !value;
        setData((prevState) => {
          return {
            ...prevState,
            data: { data: newData },
          };
        });
      }
    });
  };
  const handleDelete = (id) => {
    propertyService._delete(id).then(async (res) => {
      if (res.status === 200) {
        const newData = await propertyService.getAll();
        setData((prevState) => {
          return {
            ...prevState,
            data: newData.data,
            loading: false,
          };
        });
        message.success("Section deleted successfully");
      }
    });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    setData((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const newData = await propertyService.getAll(pagination.current);
    setData((prevState) => {
      return {
        ...prevState,
        data: newData.data,
        loading: false,
      };
    });
  };
  return (
    <Layout
      title="Propiedades"
      action={
        <Modal button="Nueva propiedad" title="Nueva propiedad">
          <NewPropertyForm updateData={handleUpdateData} />
        </Modal>
      }
    >
      {error ? (
        <Empty
          className="mt-30 mt-80-md"
          description="Lo sentimos no hay datos disponibles"
        />
      ) : (
        <Skeleton loading={loading}>
          <Table pagination={{ current: meta.current_page , pageSize: meta.per_page, total: meta.total}} loading={false} columns={columns} data={data} onChange={handleTableChange} />
          {/* <Table columns={columns} data={data} /> */}
          {/* <Table pagination={{ current: current_page, pageSize: per_page, total: total }} loading={false} columns={columns} data={data} onChange={handleTableChange} /> */}
        </Skeleton>
      )}
    </Layout>
  );
};

export default Properties;
