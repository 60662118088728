import React, { useState, useEffect, useRef } from "react";

import { Empty, Skeleton, Row, Col, Button } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../../../components/Layout";
import Tabs from "../../../../components/Tabs";
import TreeSections from "../../../../components/TreeSection";
import CreateForm from "../createForm";
import { useFetch } from "../../../../hooks";
import { propertyService } from "../../../../services/property";
import { areasService } from "../../../../services/sectionsAreas";
import { filter, flatten, map } from "lodash";
const Properties = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    data: [],
    sections: [],
    loading: true,
    selectedQuestions: [],
  });
  // const { data, loading, error } = useFetch(propertyService.show(id));
  const [currentTab, setCurrentTab] = useState("secciones");
  useEffect(() => {
    const getData = async () => {
      const { data } = await propertyService.show(id);
      console.log(data);
      // const reqSections = await areasService.getAllFormatted();
      // console.log(reqSections.data)
      setInfo((prevState) => {
        return {
          ...prevState,
          sections: data.data.sections,
          data: data.data.property, // no es necesario pero lo dejamos por si acaso
          loading: false,
        };
      });
    };
    getData();
  }, []);
  console.log(info.data);
  const itemEls = useRef(new Array());
  const handleSubmit = () => {
    const allCriterias = [];
    itemEls.current.forEach((element) => {
      const criteria = filter(element.state.checkedKeys, function (item) {
        return item.includes("c-");
      });
      allCriterias.push(criteria);
    });
    const ids_criteria = map(flatten(allCriterias), function (criteria) {
      return criteria.replace("c-", "");
    });
    propertyService
      .createProgram(id, { questions: ids_criteria })
      .then((res) => {
        console.log(res);
        navigate("/admin/properties/list");
      });
  };
  return (
    <Layout title="Agregar Propiedad">
      {false ? (
        <Empty
          className="mt-30 mt-80-md"
          description="Lo sentimos no hay datos disponibles"
        />
      ) : (
        <Skeleton loading={info.loading} paragraph={{ rows: 20 }}>
          <Tabs
            destroyInactiveTabPane={true}
            //   large
            activeKey={currentTab}
            // tabBarExtraContent={<div>TEST</div>}
            onChange={(activeKey) => {
              setCurrentTab(activeKey);
              // if (cart.length > 0) props.clearCart();
            }}
          >
            <div titulo="Secciones" tabkey="secciones" forceRender={true}>
              <Row type="flex" align="center">
                <Col xs={12} lg={6}>
                  <p className="primary-color">
                    Selecciona las secciones, areas y preguntas que consideres,
                    a continuación haz click en "guardar".
                  </p>
                </Col>
                <Col xs={12} lg={6} align="end">
                  <Button
                    type="primary"
                    size="large"
                    style={{ minWidth: 150 }}
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
              <Row>
                {info.sections &&
                  info.sections.map((section, index) => {
                    console.log(section);
                    // const getRef = (element) => (itemEls.current.push(element))
                    return (
                      <Col xs={12} lg={4} key={section.id}>
                        <TreeSections
                          defaultExpandedKeys={[section.key || null]}
                          defaultCheckedKeys={section.selectedKeys || null}
                          checkable
                          ref={(element) => (itemEls.current[index] = element)}
                          data={[section]}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </div>
            <div titulo="Información" tabkey="info" forceRender={true}>
              <Row>
                <Col xs={12} lg={8}>
                  <CreateForm initialValues={info.data} edit={true} />
                </Col>
              </Row>
            </div>
          </Tabs>
        </Skeleton>
      )}
    </Layout>
  );
};

export default Properties;
