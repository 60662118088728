import React from "react";
import { Row, Col, Select, Slider, Divider } from "antd";
import { Pie, Bar, Column } from "@ant-design/plots";
import FormGroup from "../../../components/Forms/FormGroup";
import BlockContainer from "../../../components/BlockContainer";
import defaults, { chartsConf } from "../../../constants";
import { TitleH2 } from "../../../components/Titles";
import TagsIndicator from "../TagsIndicators/";
import Layout from "../../../components/Layout";
import { getPastYears, getNameMonths } from "../../../services/utils";
import { DateTime } from "luxon";
const { Option } = Select;

const Dashboard = (props) => {
  const data = [
    {
      type: "Success",
      value: 27,
    },
    {
      type: "Critical",
      value: 25,
    },
    {
      type: "Good",
      value: 20,
    },
  ];
  const data2 = [
    {
      type: "Arrival & Main Lobby",
      sales: 38,
    },
    {
      type: "Exterior Public Areas",
      sales: 52,
    },
    {
      type: "Interior Public Areas",
      sales: 61,
    },
    {
      type: "Bars & Restaurants",
      sales: 145,
    },
    {
      type: "Back of the house",
      sales: 48,
    },
  ];
  //   Short months on Back
  const data3 = [
    { month: "ene", value: 1 },
    { month: "feb", value: 0.9 },
    { month: "mar", value: 0.24 },
    { month: "abr", value: 0.19 },
    { month: "may", value: 0.5 },
    { month: "jun", value: 0.1 },
    { month: "ago", value: 0.3 },
    { month: "sep", value: 0.44 },
    { month: "oct", value: 0.23 },
    { month: "nov", value: 0.44 },
    { month: "dic", value: 0.5 },
  ];
  const config = {
    showTitle: true,
    title: "TEST",
    position: "center",
    data,
    ...chartsConf.pie,
  };
  const configBar = {
    data: data2,
    columnWidthRatio: 0.4,
    ...chartsConf.bar,
  };
  const configColumn = {
    data: data3,
    ...chartsConf.bar,
    xField: "month",
    yField: "value",
    seriesField: "value",
    height: 250,
    columnWidthRatio: 0.4,
    color: ({ value }) => {
      console.log(value);
      if (value > 80) {
        return defaults.colors.success;
      } else if (value < 80 && value >= 60) {
        return defaults.colors.danger;
      }
      return defaults.colors.warning;
    },
    yAxis: { grid: null },
    xAxis: {
      grid: null,
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  const marks = Object.assign(
    {},
    getNameMonths("short").map((month) => {
      return {
        style: {
          textTransform: "uppercase",
          fontSize: "11px",
        },
        label: <span>{month}</span>,
      };
    })
  );
  const currentMonth = DateTime.now().month - 1;
  return (
    <Layout title='Dashboard'>
      <Row
        type='flex'
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 16 },
          { xs: 8, sm: 16, md: 24, lg: 16 },
        ]}
      >
        <Col xs={12} sm={6} xl={2}>
          <FormGroup label='Año' type='block' bold='true'>
            <Select defaultValue={DateTime.now().year} style={{ width: 160 }}>
              {getPastYears().map((year) => {
                return (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                );
              })}
            </Select>
          </FormGroup>

          {/* <TagsIndicator data={{ total: 42, acceptable: { number: 10, percentage: 32 }, critical: { number: 10, percentage: 32 }, below: { number: 10, percentage: 36 } }} /> */}
        </Col>
        <Col xs={12} xl={4}>
          <Slider marks={marks} range={true} step={null} defaultValue={[0, currentMonth]} max={11} />
        </Col>
      </Row>
      <Row gutter={20} type='flex' justify='center'>
        {/* Aca debe haber un map */}
        <Col xs={12} lg={6} xl={5}>
          <BlockContainer className='mb-40 mt-20'>
            <Row type='flex' align='top' gutter={20}>
              <Col xs={12}>
                <TitleH2 className='uppercase'>
                  <strong>Dream Sands Cancún</strong>
                </TitleH2>
                <TagsIndicator data={{ total: 42, acceptable: { number: 10, percentage: 32 }, critical: { number: 10, percentage: 32 }, below: { number: 10, percentage: 36 } }} />
                {/* TODO: add Responsive condition */}
                <Divider style={{ marginLeft: "-1rem", width: "calc(100% + 2rem)" }} />
              </Col>
              <Col xs={12} md={4} xl={4} style={{ borderRight: "1px solid #DADADA" }}>
                <p>
                  <strong>Property Overall Results</strong>
                </p>
                <Pie {...config} />
              </Col>
              <Col xs={12} md={8} xl={8}>
                <p>
                  <strong>Area Overall Results</strong>
                </p>
                <Bar {...configBar} />
              </Col>
              <Col xs={12}>
                <Divider style={{ marginLeft: "-1rem", width: "calc(100% + 2rem)" }} />
                <p>
                  <strong>Area Overall Results</strong>
                </p>
                <Column {...configColumn} />
              </Col>
            </Row>
          </BlockContainer>
        </Col>
        <Col xs={12} lg={6} xl={5}>
          <BlockContainer className='mb-40 mt-20'>
            <Row type='flex' align='top' gutter={20}>
              <Col xs={12}>
                <TitleH2 className='uppercase'>
                  <strong>Dream Sands Cancún</strong>
                </TitleH2>
                <TagsIndicator data={{ total: 42, acceptable: { number: 10, percentage: 32 }, critical: { number: 10, percentage: 32 }, below: { number: 10, percentage: 36 } }} />
                {/* TODO: add Responsive condition */}
                <Divider style={{ marginLeft: "-1rem", width: "calc(100% + 2rem)" }} />
              </Col>
              <Col xs={12} md={4} xl={4} style={{ borderRight: "1px solid #DADADA" }}>
                <p>
                  <strong>Property Overall Results</strong>
                </p>
                <Pie {...config} />
              </Col>
              <Col xs={12} md={8} xl={8}>
                <p>
                  <strong>Area Overall Results</strong>
                </p>
                <Bar {...configBar} />
              </Col>
              <Col xs={12}>
                <Divider style={{ marginLeft: "-1rem", width: "calc(100% + 2rem)" }} />
                <p>
                  <strong>Area Overall Results</strong>
                </p>
                <Column {...configColumn} />
              </Col>
            </Row>
          </BlockContainer>
        </Col>
      </Row>
    </Layout>
  );
};

export default Dashboard;
