import React from "react";
import { Avatar, Menu, Dropdown } from "antd";
import { useCookies } from "react-cookie";
import { userService } from "../../services/user";
const OverLayMenu = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["opa_user"]);
  const onHandleLogout = async () => {
    //! TODO: connect with logout API
    // Aunque en login elimina la cookie (hay duplicidad de código) TODO: revisar
    // Es necesario el PATH
    try {
      const logout = await userService.logout();
      console.log(logout.data);
      removeCookie("opa_user", { path: "/" });
    } catch (error) {
      alert(error.message);
    }
  };
  const name =
    cookies["opa_user"] !== undefined ? cookies["opa_user"].name : "Invited";
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="1">
            <span
              style={{ display: "block" }}
              onClick={() => {
                onHandleLogout();
              }}
            >
              Logout
            </span>
          </Menu.Item>
        </Menu>
      }
    >
      <span style={{ cursor: "pointer" }} onClick={(e) => e.preventDefault()}>
        <Avatar
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            marginRight: 10,
          }}
        >
          {name.slice(0, 1)}
        </Avatar>
        Welcome, {name}
      </span>
    </Dropdown>
  );
};

export default OverLayMenu;
