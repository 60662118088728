import React, { useState } from "react";
// import { Outlet } from "react-router-dom";
import { Layout, Drawer, Button } from "antd";
import styles from "./styles.module.sass";
import useMedia from "use-media";
import Menu from "../Menu/index.js";
import defaults from "../../constants";
import OverlayMenu from "../Menu/overlayMenu.js";
import HeaderTitle from "../HeaderTitle";
import LogoColor from "../../img/logos/logo-color.svg";
import LogoFooter from "../../img/logos/icono-lupa.svg";
const { Header, Content, Footer, Sider } = Layout;

const LayoutApp = ({ children, title = "N/A", action = null }) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ minWidth: defaults.breakpoints.md });
  const year = new Date().getFullYear();
  return (
    <main className="children">
      <Layout>
        {!isMobile ? (
          <Drawer
            title={
              <div>
                Menu
                <Button
                  ghost
                  size="small"
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="pull-right"
                >
                  {/* <span className="icon icon-cross-circle" /> */}
                  <span className="icon icon-cross" />
                </Button>
              </div>
            }
            placement="left"
            className="custom-drawer hidden-md"
            bodyStyle={{ padding: 0, backgroundColor: "#332F3A" }}
            closable={false}
            onClose={() => {
              setVisible(false);
            }}
            visible={visible}
          >
            <Menu isMobile={true} class />
          </Drawer>
        ) : (
          <Sider className="sider" width={250}>
            {/* <div className='logo'>
              <img src={LogoColor} alt='logo' />
            </div> */}
            <Menu isMobile={false} />
          </Sider>
        )}

        {/* </Drawer> */}
        <Layout>
          <Header className={styles.custom_header}>
            <>
              {!isMobile ? (
                <>
                  <img style={{ maxWidth: 70 }} src={LogoColor} alt="" />
                  <Button
                    onClick={() => {
                      setVisible(!visible);
                    }}
                  >
                    {/* Menu */}
                    <span className="icon icon-menu" />
                  </Button>
                </>
              ) : (
                <OverlayMenu />
              )}
            </>
          </Header>
          {/* <Header className='site-layout-sub-header-background' style={{ padding: 0 }} /> */}
          <Content>
            <HeaderTitle title={title} action={action} />
            <div className={styles.content}>
              {/* puede ir dentro de cada Pagina para evitar usar Context */}
              <div
                className={styles.content__inner}
                style={{ minHeight: "calc(100vh - 133px  )" }}
              >
                {/* <Outlet /> */}
                {children}
              </div>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <p>
              <img src={LogoFooter} alt="" />
            </p>
            Copyright © {year} Oasis Hoteles & Resorts
          </Footer>
        </Layout>
      </Layout>
    </main>
  );
};

export default LayoutApp;
