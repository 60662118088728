import React, { useEffect } from "react";
// we  load all routes in our app
import Navigation from "./routes";
import { AbilityContext } from "../components/Can";
import ability from "../config/ability.js";
import { useCookies } from "react-cookie";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import DB from "../store";
// import luxon
// we import all styles from antd
import "antd/dist/antd.less";
import "../styles/main.sass";
// const requestPermission = () => {
//   Notification.requestPermission((status) => {
//     console.log('Notification permission status', status)
//   })
// }
const App = () => {
  // requestPermission()
  const [cookies, setCookie, removeCookie] = useCookies(["opa_user"]);

  if (process.env.NODE_ENV !== "production") {
    window.ability = ability;
  }
  const navigate = useNavigate();
  // solo para actualizar (por si refresca la pagina)
  useEffect(() => {
    if (cookies["opa_user"]) {
      const newRules = cookies["opa_user"].permissions.map((ele) => {
        const parts = ele.split("-");
        return { action: parts[0], subject: parts[1] };
      });
      ability.update(newRules);
    }
    if (navigator.serviceWorker) {
      // Este listener solamente sifve para las auditorias xD
      console.log("SI SETEA LISTENER");
      navigator.serviceWorker.addEventListener("message", function (event) {
        console.log(event);
        if (event.data.type == "REPLAY_SUCCESS") {
          message.success("Sincronizacion realizada con éxito!");
        }
        if (event.data.type == "ADD_TO_QUEUE") {
          console.log(event.data.payload);
          // En teoria solo debe ser enn esta URL o la de upload
          const uuid = event.data.payload.url.split("/");
          DB.getItem("area-audit_" + uuid[uuid.length - 2])
            .then(function (values) {
              console.log(values);
              values.audited_at = new Date();
              DB.setItem("area-audit_" + uuid[uuid.length - 2], values).then(
                function (e) {
                  navigate(-1);
                }
              );
              // DB.getItem("audit_" + values.audit.id).then((e) => {
              //   console.log(e)
              // });
            })
            .catch(function (err) {
              console.log(err);
            });
          // area-audit_{NUMBER} (Cuestionario para auditar)  audits/area/582
          // audit_{NUMBER} (Lista las secciones y areas de la Auditoria) audits/26
          // audits (todas las auditorias) audits/scheduled
          // tomamos la URL de donde vino el cambio y actualizamos la BD
          message.success("Agregado A LA COLA");
        }
      });
    }
  }, []);
  return (
    <AbilityContext.Provider value={ability}>
      <Navigation />
    </AbilityContext.Provider>
  );
};

export default App;
