import { Typography, Tag, Input, Tooltip, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.sass";
import { map } from "lodash";
import { areasService } from "../../../../../services/sectionsAreas";
const SectionItem = ({ data, areas, deleteArea, section_id }) => {
  const { Paragraph } = Typography;
  const [text, setText] = useState(data.name);
  const [tagData, setDataTag] = useState({
    tags: data.questions || [], //format ({id,name,status})
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
  });

  const saveInputRef = useRef(null);
  const saveEditInputRef = useRef(null);
  const showInput = () => {
    setDataTag({ ...tagData, inputVisible: true });
  };
  useEffect(() => {
    if (tagData.inputVisible == true) {
      saveInputRef.current.focus();
    }
  }, [tagData.inputVisible]);
  const handleInputChange = (e) => {
    // areaService.addQuestion(data.id, e.target.value);
    setDataTag({ ...tagData, inputValue: e.target.value });
  };
  const handleEditInputChange = (e) => {
    setDataTag({ ...tagData, editInputValue: e.target.value });
  };

  const handleInputConfirm = () => {
    const { inputValue } = tagData;
    if (!inputValue) {
      message.error("El nombre del item no puede estar vacio");
      return false;
    }
    areasService
      .addQuestion(data.id, { name: inputValue })
      .then((response) => {
        console.log(response);
        let { tags } = tagData;
        const pos = tags
          .map(function (e) {
            return e.name;
          })
          .indexOf(inputValue);
        setDataTag({
          ...tagData,
          tags: response.data.criteria,
          inputVisible: false,
          inputValue: "",
        });
      })
      .catch((e) => {
        message.error("Error al agregar la pregunta: " + e.error);
      });
  };
  const handleEditInputConfirm = (id) => {
    const { tags, editInputIndex, editInputValue } = tagData;
    areasService
      .updateQuestion(id, { name: editInputValue })
      .then((response) => {
        let newTags = [...tags];
        newTags[editInputIndex].name = editInputValue;
        setDataTag({
          ...tagData,
          tags: newTags,
          editInputIndex: -1,
          editInputValue: "",
        });
      })
      .catch((e) => {
        message.error("Error al agregar la pregunta");
      });
  };
  const handleAreaEdited = (value) => {
    const oldName = text;
    setText("Cargando...");
    areasService
      .updateArea(data.id, { name: value, section_id })
      .then((response) => {
        const { data } = response;
        setText(data.name);
        message.success("Se actualizo correctamente");
      })
      .catch((e) => {
        message.error("Error al actualizar: " + e.error);
        setText(oldName);
      });
  };
  const handleRemoveItem = (removedTagId) => {
    areasService
      .removeQuestion(data.id, { criteria_id: removedTagId })
      .then((response) => {
        console.log(response);
        // let { tags } = tagData;
        setDataTag({
          ...tagData,
          tags: response.data.criteria,
          inputVisible: false,
          inputValue: "",
        });
        message.success("Item Eliminado Correctamente");
      })
      .catch((e) => {
        message.error("Error al agregar la pregunta: " + e.error);
      });
    // const tags = tagData.tags.filter((tag) => tag.name !== removedTag);
    // setDataTag({ ...tagData, tags });
  };
  const handleRemoveArea = () => {
    const id = data.id;
    areasService.deleteArea(id).then((response) => {
      // const { data } = response;
      console.log(response.data);
      const newAreas = areas.filter(function (area) {
        return area.id !== id;
      });
      deleteArea(newAreas);
    });
  };
  return (
    <div className={styles.section}>
      <div className={styles.section__title}>
        <Paragraph
          editable={{ onChange: handleAreaEdited }}
          className={styles.editable}
          strong={true}
        >
          {text}
        </Paragraph>
        {/* <Button type='ghost' danger size='small' onClick={handleRemoveArea}>
          <span className='icon icon-trash'></span>
        </Button> */}
      </div>
      <div className={styles.section__body}>
        <>
          {map(tagData.tags, (tag, index) => {
            if (tagData.editInputIndex === index) {
              return (
                <Input
                  ref={saveEditInputRef}
                  key={tag.name}
                  style={{ width: 250 }}
                  size="small"
                  className={styles.tagInput}
                  value={tagData.editInputValue}
                  onChange={handleEditInputChange}
                  // onBlur={() => {
                  //   handleEditInputConfirm(tag.id);
                  // }}
                  onPressEnter={() => {
                    handleEditInputConfirm(tag.id);
                  }}
                />
              );
            }

            const isLongTag = tag.name.length > 40;

            const tagElem = (
              <Tag
                style={{ marginBottom: 3 }}
                className={styles.editTag}
                key={tag.name}
                closable
                onClose={() => handleRemoveItem(tag.id)}
              >
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setDataTag(
                        {
                          ...tagData,
                          editInputIndex: index,
                          editInputValue: tag.name,
                        },
                        () => {
                          saveEditInputRef.current.focus();
                        }
                      );
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.name.slice(0, 40)}...` : tag.name}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag.name} key={tag.name}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {tagData.inputVisible && (
            <Input
              ref={saveInputRef}
              type="text"
              style={{ width: 150 }}
              size="small"
              className={styles.tagInput}
              value={tagData.inputValue}
              onChange={handleInputChange}
              // onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {!tagData.inputVisible && (
            <Tag className={styles.addTag} onClick={showInput}>
              + New Tag
            </Tag>
          )}
        </>
      </div>
    </div>
  );
};

export default SectionItem;
