import React from "react";
import { Button, Row, Col, Select, Divider } from "antd";
import { Pie, Bar } from "@ant-design/plots";
import StatisticsTable from "../../components/statisticsTable";
import FormGroup from "../../components/Forms/FormGroup";
import BlockContainer from "../../components/BlockContainer";
import defaults, { chartsConf } from "../../constants";
import { TitleH2 } from "../../components/Titles";
import TagsIndicator from "./TagsIndicators";
import Layout from "../../components/Layout";
import History from "../../config/history";
const { Option } = Select;
const Dashboard = (props) => {
  const data = [
    {
      type: "Success",
      value: 27,
    },
    {
      type: "Critical",
      value: 25,
    },
    {
      type: "Good",
      value: 20,
    },
  ];
  const data2 = [
    {
      type: "Arrival & Main Lobby",
      sales: 38,
    },
    {
      type: "Exterior Public Areas",
      sales: 52,
    },
    {
      type: "Interior Public Areas",
      sales: 61,
    },
    {
      type: "Bars & Restaurants",
      sales: 145,
    },
    {
      type: "Back of the house",
      sales: 48,
    },
  ];
  const config = {
    showTitle: true,
    title: "TEST",
    position: "center",
    data,
    ...chartsConf.pie,
  };
  const configBar = {
    data: data2,
    ...chartsConf.bar,
  };
  console.log(History);
  return (
    <Layout title='Dashboard'>
      <Row
        type='flex'
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 16 },
          { xs: 8, sm: 16, md: 24, lg: 16 },
        ]}
      >
        <Col xs={12} sm={6} xl={4}>
          <FormGroup label='Sección' type='block'>
            <Select defaultValue='Todas' style={{ width: 160 }}>
              <Option value='jack'>Jack</Option>
              <Option value='lucy'>Lucy</Option>
              <Option value='disabled' disabled>
                Disabled
              </Option>
              <Option value='Yiminghe'>yiminghe</Option>
            </Select>
          </FormGroup>
          <TitleH2 className='uppercase mt-20 mb-15'>
            <strong>Total of areas audited</strong>
          </TitleH2>
          {/* Son datos de prueba nada mas */}
          <TagsIndicator data={{ total: 42, acceptable: { number: 10, percentage: 32 }, critical: { number: 10, percentage: 32 }, below: { number: 10, percentage: 36 } }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={10} xl={5}>
          <BlockContainer className='mb-40 mt-20'>
            <Row type='flex' align='top' gutter={20}>
              <Col xs={12} md={4} xl={4} style={{ borderRight: "1px solid #DADADA" }}>
                <p>
                  <strong>Property Overall Results</strong>
                </p>
                <Pie {...config} />
                {/* <Divider type='vertical' /> */}
              </Col>
              {/* <Col xs={1}>
                <Divider type='vertical' />
              </Col> */}
              <Col xs={12} md={8} xl={8}>
                <p>
                  <strong>Area Overall Results</strong>
                </p>
                <Bar {...configBar} />
              </Col>
            </Row>
          </BlockContainer>
        </Col>
      </Row>
      <Row
        type='flex'
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 },
        ]}
      >
        <Col xs={12} sm={6} xl={3}>
          <StatisticsTable title='Prueba numero 1 de que esta Largo' />
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <StatisticsTable title='Titulo Corto' />
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <StatisticsTable />
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <StatisticsTable />
        </Col>
      </Row>
    </Layout>
  );
};

export default Dashboard;
