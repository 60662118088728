import React, { useState, useEffect } from "react";
import { Empty, Skeleton, Button, Row, Col, Select } from "antd";
import Axios from "axios";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { map, size } from "lodash";
import defaults from "../../constants";
import Layout from "../../components/Layout";
import StatisticsTable from "../../components/statisticsTable";
import FormGroup from "../../components/Forms/FormGroup";
import DB from "../../store";
import styles from "../../components/statisticsTable/styles.module.sass";
import { auditService } from "../../services/audits";
import Tabs from "../../components/Tabs";
const { Option } = Select;

const AuditsEdit = () => {
  const [data, setData] = useState({
    loading: true,
    data: [],
  });
  const [currentTab, setCurrentTab] = useState("common");
  const { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await auditService.show(id);
        DB.setItem("audit_" + id, res.data)
          .then(function () {
            return DB.getItem("audit_" + id);
          })
          .then(function (value) {
            setData({
              loading: false,
              data: value,
            });
          })
          .catch(function (err) {
            alert(err);
          });
      } catch (error) {
        DB.getItem("audit_" + id)
          .then(function (value) {
            setData({
              loading: false,
              data: value,
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    };
    getData();
  }, []);

  const columns = [
    {
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Area</strong>;
      },
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (text, record, index) => {
        return (
          <span>
            {`${index + 1}.-`} {text}
          </span>
        );
      },
      className: styles.header_column,
    },
    {
      className: styles.header_column,
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Status</strong>;
      },
      align: "center",
      dataIndex: "audited_at",
      width: 80,
      key: "audited_at",
      render: (text, record, index) => {
        if (!text)
          return <span className="icon icon-hourglass danger-color"></span>;
        return (
          <span className="icon icon-checkmark-circle primary-color"></span>
        );
      },
    },
    {
      className: styles.header_column,
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Action</strong>;
      },
      width: 80,
      align: "center",
      dataIndex: "audited_at",
      key: "audited_at",
      render: (text, record, index) => {
        return (
          <Button type="link" href="#" className="no-padding">
            <Link to={"/audits/area/" + record.id}>
              <div
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: 30,
                  backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)`,
                }}
              >
                {!text ? (
                  <span className="icon icon-pencil"></span>
                ) : (
                  <span className="icon icon-eye"></span>
                )}
              </div>
            </Link>
          </Button>
        );
      },
    },
  ];
  console.log(data.data);
  return (
    <Layout title="Auditorias Pendientes">
      <Skeleton loading={data.loading} paragraph={{ rows: 20 }}>
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={currentTab}
          onChange={(activeKey) => {
            setCurrentTab(activeKey);
          }}
        >
          <div titulo="Areas Comunes" tabkey="common" forceRender={true}>
            <Row
              type="flex"
              gutter={[
                { xs: 8, sm: 16, md: 24, lg: 32 },
                { xs: 8, sm: 16, md: 24, lg: 32 },
              ]}
            >
              {data.data.audit_areas &&
                map(data.data.audit_areas, (ele, key) => {
                  return (
                    <Col xs={12} sm={6} xl={4}>
                      <StatisticsTable
                        noID
                        fixed="true"
                        title={`${key} (${ele.length})`}
                        columns={columns}
                        data={ele}
                        summary={false}
                      />
                    </Col>
                  );
                })}
            </Row>
          </div>
          {data.data.audit_rooms && size(data.data.audit_rooms) > 0 && (
            <div titulo="Habitaciones" tabkey="rooms" forceRender={true}>
              <Row
                type="flex"
                gutter={[
                  { xs: 8, sm: 16, md: 24, lg: 32 },
                  { xs: 8, sm: 16, md: 24, lg: 32 },
                ]}
              >
                {data.data.audit_rooms &&
                  map(data.data.audit_rooms, (ele, key) => {
                    return (
                      <Col xs={12} sm={6} xl={4}>
                        <StatisticsTable
                          noID
                          fixed="true"
                          title={"Room. No. " + ele.room.room_number}
                          columns={columns}
                          data={ele.items}
                          summary={false}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </div>
          )}
        </Tabs>
      </Skeleton>
    </Layout>
  );
};

export default AuditsEdit;
